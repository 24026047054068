import React, { useEffect, useState } from "react";
import MROButton from "../../buttons";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Close as CloseIcon } from "@material-ui/icons";
import { ReactComponent as UploadIcon } from "../../../assets/icons/UploadDoc.svg";
import DataService from "../../../lib/services/api";
import Document from "./pdf.png";
import ImageFile from "./Image.png";
import { BASE_API_URL_BOXR } from "../../../lib/constants";
import clxc from "clsx";
import { Box, CircularProgress, Typography, useTheme } from "@material-ui/core";
import { useContext } from "react";
import { ToastMessageContext } from "lib/contexts/message_context";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  fileLabel: {
    color: theme.palette.primary.main,
    marginBottom: "5px",
    display: "block",
  },
  fileView: {
    padding: "15px",
    border: "1px solid " + theme.palette.primary.main,
    minHeight: "136px",
    borderRadius: " 18px",
    marginBottom: "12px",
    "& ul": {
      display: "flex",
      padding: "0",
      listStyle: "none",
      flexWrap: "wrap",
      "& li": {
        margin: "0 10px",
        textAlign: "center",
        "& div": {
          width: "60px",
          height: "60px",
          position: "relative",
          marginBottom: "10px",
          "& img": {
            maxWidth: "100%",
            height: "-webkit-fill-available",
            objectFit: "cover",
          },
          "& div": {
            position: "absolute",
            top: "0",
            right: "0",
            width: "20px",
            backgroundColor: "#F44336",
            height: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#fff",
            borderRadius: "50%",
          },
        },
        "& span": {
          width: "60px",
          display: "-webkit-box",
          WebkitLineClamp: "1",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        },
      },
    },
  },
  fileError: {
    color: "#f44336",
  },
}));

function FileUpload(props) {
  const classes = useStyles();
  const {
    width,
    accept,
    name,
    action,
    multiple,
    defaultValue,
    process,
    id,
    label,
    error,
    onDelete,
    showBorderOnNoFiles,
    viewOnly,
    uploadButtonView,
    APIUrl,
    isDeleting,
    isLoading,
    startIcon,
  } = props;

  const [files, setFiles] = useState([]);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const message = useContext(ToastMessageContext);
  const theme = useTheme();
  // const fileList = Array.from(files || [])
  useEffect(() => {
    console.log("Calling fileUpload component")
    if (process && files && files[0] && id) {
      const formData = new FormData();
      formData.append(name, files[0]);
      formData.append("id", id);
      DataService.updateData(formData, "", "authDoc").then((res) => {});
    }
  }, [process]);

  const handleChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const totalFiles = (defaultValue ?  defaultValue?.length : 0) + files.length + selectedFiles.length;
    // Check if the total number of files exceeds the limit
    if (totalFiles > 20) {
      message.showToastMessage({
        message: "You can only upload a maximum of 20 files.",
        variant: "error"
      });
      return;
    }
  
    const validFiles = selectedFiles.filter((file) => {
      const bytes = file.size;
      const fileSize = Math.round(bytes / (1024 * 1024));
      if (fileSize >= 20) {
        message.showToastMessage({
          message: `File "${file.name}" is too large! - Greater than 20MB`,
          variant: "error"
        });
        return false;
      }
      return true;
    });
  
    if (multiple) {
      setFiles((prevState) => [...prevState, ...validFiles]);
    } else if (validFiles.length > 0) {
      setFiles([validFiles[0]]);
    }
  };

  const removeFile = (index) => () => {
    let _files = files.filter((file, i) => {
      return i !== index;
    });
    setFiles(_files);
  };

  useEffect(() => {
    typeof props.setFiles === "function" && props.setFiles([...files]);
  }, [files.length]);
  return (
    <div>
      {label && (
        <label
          className={clxc(
            classes.fileLabel,
            error && !files?.length ? classes.fileError : ""
          )}
        >
          {" "}
          {label || ""}
        </label>
      )}

      {isLoading ? (
        <Box
          width="100%"
          minHeight="50px"
          display="flex"
          justifyContent="center"
          background="transparent"
          alignItems="center"
        >
          <CircularProgress color="primary" size={35} />
        </Box>
      ) : files?.length || defaultValue?.length || showBorderOnNoFiles ? (
        <div style={{ width: width ?? "100%" }} className={classes.fileView}>
          {showBorderOnNoFiles && !defaultValue?.length && !files?.length && (
            <Typography variant="subtitle2" style={{ width: width ?? "100%" }}>
              No files found
            </Typography>
          )}
          <ul>
            {defaultValue?.length
              ? defaultValue?.map((file, index) => {
                  const fileName = file.filename ?? `Docs ${index + 1}`;
                  let uploadImage = Document;
                  const fileExtension = file?.filename?.split(".")[1];
                  if (
                    ["jpg", "jpeg", "png"].includes(
                      fileExtension?.toLowerCase()
                    )
                  )
                    uploadImage = `${APIUrl ?? BASE_API_URL_BOXR}${file?.url}`;
                  return (
                    <li
                      key={file.id}
                      onClick={() =>
                        window.open(`${APIUrl ?? BASE_API_URL_BOXR}${file?.url}`)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <img src={uploadImage} alt={fileName} />
                        {onDelete && (
                          <>
                            {isDeleting && selectedFileId === file.id ? (
                              <CircularProgress
                                color="primary"
                                style={{
                                  width: 20,
                                  height: 20,
                                  fontSize: 14,
                                  background: "transparent",
                                  color: theme.palette.primary.main,
                                }}
                              />
                            ) : (
                              <div>
                                <CloseIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setSelectedFileId(file.id);
                                    onDelete(file?.id);
                                  }}
                                  fontSize="small"
                                />{" "}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      <span>{fileName}</span>
                    </li>
                  );
                })
              : null}
            {files.map((file, index) => {
              const fileName = file[0] && file[0]?.name?.split(".")[0];
              const objectUrl = URL.createObjectURL(file);
              let uploadImage =
                file.type === "application/pdf" ? Document : objectUrl;
              if (file[0] && file[0].type?.split("/")[0] === "image")
                uploadImage = URL.createObjectURL(file[0]);
              return (
                <li key={file ? file[0]?.lastModified : ""}>
                  <div>
                    <img src={uploadImage} />
                    <div>
                      <CloseIcon
                        style={{ cursor: "pointer" }}
                        onClick={removeFile(index)}
                        fontSize="small"
                      />
                    </div>
                  </div>
                  <span>{fileName ?? file.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      ) : null}
      {!viewOnly && (
        <>
          {!uploadButtonView && (
            <div>
              <div>
                <input
                  accept={accept || "*"}
                  className={classes.input}
                  id={name ?? "upload-file"}
                  multiple={multiple || false}
                  type="file"
                  name={name}
                  onChange={handleChange}
                  value=""
                />
              </div>
              <label htmlFor={name ?? "upload-file"}>
                <MROButton
                  variant="contained"
                  color="secondary"
                  component="span"
                  startIcon={startIcon ? startIcon : <UploadIcon />}
                >
                  {action ? action : "Upload"}
                </MROButton>
              </label>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default FileUpload;
