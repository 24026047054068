import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FileUpload from "components/form_components/fileUploadV2"
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { ICON_COLOUR } from "lib/constants/style_constants";
import makeStyles from "@material-ui/core/styles/makeStyles";
import cameraIcon from "assets/camera@2x.png";
import { InspectionServices } from "lib/services/api/operaitons/WorkShop/Inspection/InspectionService";
import { ToastMessageContext } from "lib/contexts/message_context";
import MROButton from "components/buttons";
import { BASE_API_URL_BOXP } from "lib/constants";
import { MaintenanceServices } from "lib/services/api/operaitons/WorkShop/TaskLibrary/Maintenance/maintenance_operation";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    minHeight: "30vh",
    maxHeight: "150vh",
  },
  actionButtons: {
    paddingTop: "5px",
    paddingBottom: "20px",
    textAlign: "right",
    marginRight: "20px",
    "& button:not(:last-child)": {
      marginRight: "20px",
    },
  },
}));

function ManageTask(props) {
  const {
    open,
    handleCloseDailog,
    selectedId,
    action_type,
    styleProp,
    status
  } = props;
  const classes = useStyles();
  const message = useContext(ToastMessageContext);
  const [boolState, setBoolState] = useState(false);
  const [busy, setBusy] = useState(null);
  const [actionPhotos, setActionPhotos] = useState([]);
  const [actionImage, setActionImage] = useState([]);
  const [deletedStatus, setDeletedStatus] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  console.log("action is=============================", props.permission.write, status, styleProp, boolState)

  useEffect(() => {
    getActionPhotos();
  }, []);

  useEffect(() => {
    if (actionImage.length) {
      setDeletedStatus(false)
    }
  }, [actionImage])

  const getActionPhotos = async () => {
    setBusy("get_photos");
    setIsLoading(true)
    await InspectionServices.getActionPhotos(selectedId)
      .then((res) => {
        if (res) {
          setBusy(null);
          setActionPhotos(res.data);
        }
      })
      .catch(() => {
        setBusy(null);
        message.showToastMessage({
          message: "Something went wrong. Try again!",
          variant: "error",
        });
      }).finally(() => {
        setBusy(null);
        setIsLoading(false)
      })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    formData.append("action_id", selectedId);
    formData.append("action_type", action_type);
    formData.delete("action_image[]");
    actionImage.forEach((image) => formData.append("action_image[]", image));
    setBusy("loading");
    if (deletedStatus !== true) {
      await InspectionServices.addPhotos(formData)
        .then(() => {
          setBusy(null);
          handleCloseDailog();
          message.showToastMessage({
            message: "Added successfully!",
            variant: "success",
          });
          setDeletedStatus(false)
        })
        .catch(() => {
          setBusy(null);
          handleCloseDailog();
          message.showToastMessage({
            message: "Something went wrong. Try again!",
            variant: "error",
          });
        });
      setBusy(null);
    }
    setBusy(null);
    handleCloseDailog();
  };

  const deleteFileHandler = async (id) => {
    setBusy("file-deleting");
    try {
      const response = await MaintenanceServices.deleteFile(id);

      if (response.success) {
        message.showToastMessage({
          message: "File deleted successfully",
          variant: "success",
        });
        setDeletedStatus(true);
        getActionPhotos();
      }
      setBusy(null);
    } catch (error) {
      message.showToastMessage({
        message: "Something went wrong",
        variant: "error",
      });
      setBusy("file-deleting-error");
    }
  };

  return (
    <div>
      <Dialog
        onClose={handleCloseDailog}
        aria-labelledby="customized-dialog-title"
        open={open}
        classes={{ paper: classes.dialogPaper }}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseDailog}>
          <Grid style={{ display: "flex", justifyContent: "space-between" }}>
            <Box display="flex" alignItems="center">
              <Typography
                style={{ fontSize: "17px" }}
                variant="h5"
                color="primary"
              >
                Action Photos &nbsp;&nbsp;
                {!boolState && status != "Closed" && props.permission.write && (
                  <IconButton style={{ display: styleProp }}>
                    <EditIcon
                      fontSize="small"
                      htmlColor={ICON_COLOUR}
                      onClick={() => setBoolState(true)}
                    />
                  </IconButton>
                )}
              </Typography>
            </Box>
            <IconButton>
              <Box display="flex" justifyContent="center">
                {" "}
                <img src={cameraIcon} alt="Icon" />{" "}
              </Box>
            </IconButton>
          </Grid>
          <Typography style={{ fontSize: "12px" }}>
            Upload any relevant images - E.g., defect photos etc
          </Typography>
        </DialogTitle>
        <form noValidate onSubmit={handleSubmit}>
          <DialogContent>
            <Grid
              style={{
                width: "500px",
                height: "200px",
              }}
            >
              <FileUpload
                defaultValue={
                  (actionPhotos !== undefined && boolState === false) ||
                    boolState === true
                    ? actionPhotos?.map((item) => ({
                      filename: item?.file_name,
                      url: `${item?.path}`,
                      id: item?.id,
                    }))
                    : ""
                }
                setFiles={(files) => {
                  setActionImage(files);
                }}
                onDelete={boolState === false ? null : deleteFileHandler}
                APIUrl={""}
                showBorderOnNoFiles={true}
                name="action_image[]"
                multiple={true}
                action={"Upload Document"}
                id="action_image[]"
                isLoading={isLoading}
                isDeleting={busy === "file-deleting"}
                uploadButtonView={boolState === false}
              />
            </Grid>
          </DialogContent>
          {boolState === true && (
            <div className={classes.actionButtons}>
              <MROButton variant={"contained"} onClick={handleCloseDailog}>
                Cancel
              </MROButton>
              <MROButton
                loading={busy === "loading"}
                variant={"contained"}
                color={"primary"}
                type={"submit"}
              >
                Complete
              </MROButton>
            </div>
          )}
        </form>
      </Dialog>
    </div>
  );
}

export default ManageTask;
